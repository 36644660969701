<template>
  <div class="product-upgrades-header-component">
    <div class="main">
      <span class="title" v-if="config.title">{{ config.title }}</span>
      <span class="separator">|</span>
      <span>{{ getActiveVehicleDisplayName() }}</span>
    </div>
    <button class="close-button" @click="$emit('close')"></button>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.product-upgrades-header-component {
  background: $brand-color-1;
  padding: 10px 30px;
  box-shadow: $drop-shadow;

  .main {
    display: flex;
    align-items: center;
    line-height: 1.25;
    font-size: 0.9375rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;

    .title {
      font-size: 1.25rem;
      font-style: normal;
    }

    .separator {
      font-size: 1.25rem;
      font-weight: normal;
      margin: 0 15px;
    }
  }

  .close-button {
    background: transparent;
    position: absolute;
    right: 15px;
    top: 10px;
    width: 24px;
    height: 24px;
    opacity: 1;
    border: none;

    &:before,
    &:after {
      background: #fff;
      position: absolute;
      top: 0;
      left: 11px;
      content: " ";
      height: 24px;
      width: 2px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 480px) {
  .product-upgrades-header-component {
    .main {
      display: block;

      .title {
        display: block;
        font-size: 1.125rem;
      }

      .separator {
        display: none;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { modalService } from "@/services";

export default {
  name: "ProductUpgradesHeader",
  props: ["config"],
  computed: {
    ...mapGetters("asset", ["getActiveVehicleDisplayName"]),
    isMobile() {
      return this.$isMobile();
    },
  },
};
</script>
